.calendar-app {
    display: flex;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
}
  
.calendar-app-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
}

.calendar-app-sidebar h2 {
    font-size: 22px;
    font-weight: bold;
}
.calendar-app-sidebar-section {
    padding: 1em 2em;
}
  
.calendar-app-main {
    flex-grow: 1;
    padding: 3em;
}
  
.fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
    text-transform: capitalize;
    height: 100vh;
}

@media screen and (max-width: 774px) {
    .calendar-app-main {
        padding: 1em;
    }
    .fc-header-toolbar {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .fc-toolbar-chunk {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .fc-toolbar-chunk:nth-child(3),
    .fc-toolbar-chunk:nth-child(3) .fc-button-group {
        width: 100%;
    }
    .fc-toolbar-title {
        padding: 10px 0;
        text-align: center;
    }
    .fc .fc-timegrid-axis-cushion {
        max-width: 45px;
        text-align: center;
    }
}
@media screen and (max-width: 441px) {
    .calendar-app-main {
        padding: 0;
    }
    .fc-header-toolbar {
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    }
    .fc-toolbar-chunk {
        flex-wrap: nowrap;
        width: 100%;
    }
    .fc-button-chunk {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .fc-button-group {
        width: 100%;
    }
    .fc-today-button {
        width: 50%;
    }
} 